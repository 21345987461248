import appleLogo from "./appleLogo.png";
import qr from "./nomboloQR.png";
import androidButton from "./android-mobile-button.png";
import appleButton from "./apple-mobile-button.png";

const IOSButton = ({ styleProp, handleDownloadButton, mediaQuery }) => {

  const buttonStyle = {
    width: "121px",
    height: "auto",
    cursor: "pointer"
  };

  if (mediaQuery === false) {
    return (
      <button style={styleProp} onClick={handleDownloadButton}>
        <img id="left" src={qr} alt='left'/>
      </button>
    );
  } else {
    return (
      <div className="app-store-button-container">
        <button
          id="androidButton"
          onClick={() => {
            window.open(
              "https://play.google.com/store/apps/details?id=com.nombolo.nombolo&hl=en_US&gl=US&pli=1",
              "_blank"
            );
          }}
        >
          <img
            src={androidButton}
            style={buttonStyle}
            alt="android"
          />
        </button>
        <button
          id="appleButton"
          onClick={() => {
            window.open(
              "https://apps.apple.com/us/app/nombolo-community/id1506060452",
              "_blank"
            );
          }}
          style={buttonStyle}
        >
          <img
            src={appleButton}
            style={buttonStyle}
            alt="apple"
          />
        </button>
      </div>
    );
  }
};

export default IOSButton;
