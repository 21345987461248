import React from "react";
import stylesAbout from "./About.module.css";
import IOSButton from "../../components/Hero/IOSButton";
import { ArrowDiv } from "./ArrowDiv";
import { handleDownloadButton } from "../../components/Hero/Hero";
import { Helmet } from "react-helmet-async";

const About = ({ isMobile }) => {

  return (
    <div className={stylesAbout.about}>
      <Helmet>
        <title>About - nombolo</title>
        <meta name="description" content="Learn more about nombolo and our mission" />
      </Helmet>
      <div className={stylesAbout.heroContainer}>
        <div className={stylesAbout.iphone} >
          <h1>
            Connecting <wbr />people and places,<wbr /> uplifting small businesses
          </h1>
          <p>
            We're a Portland, OR based startup on a mission to better connect
            people, better support the places they love, and build positive
            community.
          </p>
        </div>
      </div>
      <div className={stylesAbout.mobileHeroContainer}>
        <div
          className={stylesAbout.iphone}
        >
          <h4>
            Connecting
            <span> people and places, </span>
            <span>uplifting small </span>
            businesses
            
          </h4>
          <p>
            We're a Portland, OR based startup on a mission to better connect
            people, better support the places they love, and build positive
            community.
          </p>
        </div>
      </div>
      <div className={stylesAbout.mobileAboutImg}>
        <img src="about-us-crop.png" alt="portland map background"/>
      </div>
      <div className={stylesAbout.subContainer2}>
        <p className={stylesAbout.p2}>
          <span style={{ fontWeight: 600 }}>Nombolo</span> powers an open, vibrant, informed community through asks,
          answers, and community updates.
        </p>
        <p className={stylesAbout.p2}>
          User-generated video and geo-positioned data keeps things real.
        </p>
        <h4 className={stylesAbout.h2}>
          Our reward system is customizable and accessible, <br />to help customers
          stay happy, and small businesses growing.
        </h4>
      </div>
      <div className={stylesAbout.subContainer4}>
        <div className={stylesAbout.subContainer3}>
          <img className={stylesAbout.gift} src="get-gift.png" alt="gift" />
        </div>
        <div className={stylesAbout.subContainer35}>
          <h2 className={stylesAbout.we} style={{ color: "#5C24C9", fontFamily: "Montserrat" }}>
            We built a Better App
          </h2>
          <div className={stylesAbout.boxContainer1}>
            <div className={stylesAbout.boxes}>
              <img src="time.png" alt="time" />
              <h3 >Real Time Updates</h3>
              <p >
                Real time updates keep people informed about the places and
                activities they're curious about.
              </p>
            </div>
            <div className={stylesAbout.boxes}>
              <img src="location.png" alt="location" />
              <h3 >Location-Based Q&A</h3>
              <p >
                Location-based asks & posts make it easier for people to find
                what they want where they want.
              </p>
            </div>
          </div>
          <div className={stylesAbout.boxContainer2}>
            <div className={stylesAbout.boxes}>
              <img src="gift-box.png" alt="gift-box" />
              <h3 >Accessible Rewards </h3>
              <p >
                An accessible rewards system and customer-generated marketing
                helps small businesses grow their community and their
                enterprises.
              </p>
            </div>
            <div className={stylesAbout.boxes}>
              <img src="people.png" alt="time" />
              <h3 >Building a Community</h3>
              <p >
                Users and business owners share and receive real-life, chat- and
                video-based answers, building trust & community
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={stylesAbout.container5}>
        <div className={stylesAbout.subContainer5}>
          <img
            className={stylesAbout.sparkImg}
            style={{ height: 38, width: 38 }}
            src="sparkling.png"
            alt="stars"
          />
          <h3 className={stylesAbout.we} style={{ color: "#5C24C9" }}>
            {" "}
            How it Started{" "}
          </h3>
          <p className={stylesAbout.p1}>
            New to Portland and an avid basketball player, our founder was
            looking for pickup basketball games in Portland.
          </p>
          <div>
            <div className={stylesAbout.happyGirl}>
              <p className={stylesAbout.p1} stye={{ textAlign: 'center', maxWidth: "50%" }}>
                Instead of driving all over town, he built an app to make it easy to
                Ask about games and enough numbers of players, and get Answers right
                away. <br></br> <br></br> <br></br>
                He called it <span style={{ color: "#5C24C9" }}>Nombolo</span>,
                which means numbers in Zulu. <br></br> <br></br> <br></br>
                The app gained a following, and he knew he was on to something! <br></br> <br></br> <br></br>
                And today his vision to build a better <br></br>
              </p>
              <h4 className={stylesAbout.h4}>
                <img
                  className={stylesAbout.spark1}
                  src="sparkling.png"
                  alt="stars"
                />
                Social and rewards app that builds positive community. <br></br>
                <img
                  className={stylesAbout.spark2}
                  src="sparkling.png"
                  alt="stars"
                />
              </h4>
              <img
                className={stylesAbout.happyGirlImg}
                src="happyGirl.png"
                alt="happyGirl"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={stylesAbout.container4}>
        <ArrowDiv
          handleDownloadButton={handleDownloadButton}
          isMobile={isMobile}
        />
      </div>
    </div>
  );
};

export default About;
