import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import stylesNavbar from "./Navbar.module.css";
import { NomboloBusinessIcon } from "./nomboloBusinessLogo";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <div className={stylesNavbar.navbarContainer}>
      <div className={stylesNavbar.navbar}>
        <div className={stylesNavbar.logo}>
          <NomboloBusinessIcon
            toggleState={() => setToggle(false)}
          />
        </div>
        <div className={stylesNavbar.nav}>
          <nav>
            <ul className={stylesNavbar.list}>
              <Link className={stylesNavbar.link} to="/">
                <li>home</li>
              </Link>
              <Link className={stylesNavbar.link} to="/about">
                <li>about</li>
              </Link>
              <Link className={stylesNavbar.link} to="/contact">
                <li>contact us</li>
              </Link>
              <Link className={stylesNavbar.businessLink} to="/business">
                <li>business</li>
              </Link>
            </ul>
          </nav>
        </div>
        {/* Mobile Menu */}
        <div onClick={() => setToggle(!toggle)} className={stylesNavbar.mobileMenu}>
          {toggle ? <IoMdClose /> : <FaBars />}
        </div>
      </div>
      {toggle && (
        <div className={stylesNavbar.mobileNav}>
          <nav>
            <ul className={stylesNavbar.list}>
              <Link className={stylesNavbar.link} to="/">
                <li className={stylesNavbar.li} onClick={() => setToggle(false)}>
                  home
                </li>
              </Link>
              <Link className={stylesNavbar.link} to="/about">
                <li className={stylesNavbar.li} onClick={() => setToggle(false)}>
                  about us
                </li>
              </Link>
              <Link className={stylesNavbar.link} to="/contact">
                <li className={stylesNavbar.li} onClick={() => setToggle(false)}>
                  contact us
                </li>
              </Link>
              <Link className={stylesNavbar.businessLink} to="/business">
                <li className={stylesNavbar.li} onClick={() => setToggle(false) }>
                  business
                </li>
              </Link>
            </ul>
          </nav>
        </div>
      )}
    </div>
  );
};

export default Navbar;
