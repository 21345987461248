import contactStyle from './Contact.module.css'

export const ThankYouNote = () => {

  return (
    <h2 className={contactStyle['thank-you-note']}>
      Thank you! We will get back to you soon!
    </h2>
  );

};
