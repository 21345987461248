import { React, useState, useEffect } from "react";

import { FiFacebook } from "react-icons/fi";
import { FiInstagram } from "react-icons/fi";
import { FiTwitter } from "react-icons/fi";
import { FiMail } from "react-icons/fi";

import stylesFooter from "./Footer.module.css";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {

  const location = useLocation();
  const [ footerImageUrl, setFooterImageUrl ] = useState('footer-curve-homepage.png');

  useEffect(() => {

    if (location.pathname === '/') {
      setFooterImageUrl('footer-curve-homepage.png');
    } else {
      setFooterImageUrl('footer-curve.png');
    }

  }, [location]);

  useEffect(() => {
    // Create the script element
    const script = document.createElement("script");
    script.src = "//js.hs-scripts.com/7343610.js";
    script.async = true;
    script.defer = true;
    script.id = "hs-script-loader";
    
    // Append the script to the document head
    document.head.appendChild(script);

    // Cleanup function to remove the script if the component is unmounted
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <>
      <div className={stylesFooter.footerCurve}>
        <img src={footerImageUrl}
        />
      </div>
      <div className={stylesFooter.footerContainer}>
        <div className={stylesFooter.logoImg}>
          <img src="footer-logo.png" alt="nombolo-logo"/>
        </div>
        <div
          style={{ maxWidth: "85%", alignSelf: "center" }}
          className="white-line"
        >
          <hr />
        </div>
        <div className={stylesFooter.infoSection}>
          <div className={stylesFooter.Company}>
            <h2>Company</h2>
            <Link className={stylesFooter.link} to="/about">
              {" "}
              <p>about</p>
            </Link>
            <Link className={stylesFooter.link} to="/contact">
              {" "}
              <p>contact</p>{" "}
            </Link>
          </div>
          <div className={stylesFooter.legal}>
            <h2>Legal</h2>
            <Link className={stylesFooter.link} to="/privacy">
              {" "}
              <p>privacy Policy</p>{" "}
            </Link>
            <Link className={stylesFooter.link} to="/terms">
              <p>terms & Services</p>{" "}
            </Link>
          </div>
          <div>
            <h2> Connect with us</h2>
            <div className={stylesFooter.iconsContainer}>
              <a className={stylesFooter.a} href="https://www.instagram.com/nomboloapp/">
                <FiInstagram size={"48px"} />
              </a>
              <a className={stylesFooter.a} href="https://www.facebook.com/nomboloapp/">
                <FiFacebook size={"48px"} />
              </a>
              <a className={stylesFooter.a} href="https://twitter.com/appnombolo">
                <FiTwitter size={"48px"} />
              </a>
              <a className={stylesFooter.a} href="mailto: info@nombolo.com">
                <FiMail size={"48px"} />
              </a>
            </div>
          </div>
        </div>
        {/* <div style={{maxWidth:'85%',alignSelf:'center'}} className='white-line'>
                <hr />
            </div> */}
        <div className={stylesFooter.copyright}>
          <p>© Nombolo - 2022. All rights reserved.</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
