import React from "react";
import CTAStyle from "./CTA.module.css";

const CTAButtons = ({ freeVersion }) => {

    // const handleClick = async (buttonName) => {
    //     const clickedTime = new Date().toISOString();
    //     const apiName = 'WebConnectInfoAPI'; // replace with your API name
    //     const path = '/amplify/backend/api/WebConnectInfoAPI'; // replace with your API path
    //     const payload = {
    //         body: {
    //             buttonName,
    //             clickedTime
    //         }
    //     };

    //     try {
    //         const response = await API.post(apiName, path, payload);
    //         console.log('Data sent', response);

    //     } catch (error) {
    //         console.error('Error sending data', error);
    //     }
    // };

    const sendToDynamoDB = async (name) => {
        console.log("entering dynamo")
        try {
   
          console.log("data sent")
        } catch (error) {
          console.error("Error sending data");
        }
      };

    const isAndroid = () => {
        return /Android/i.test(navigator.userAgent);
    }

    const appStoreLink = 'https://apps.apple.com/us/app/nombolo-community/id1506060452';
    const playStoreLink = 'https://play.google.com/store/apps/details?id=com.nombolo.nombolo&hl=en_US&gl=US';
    const nomOneLink = isAndroid() ? playStoreLink : appStoreLink;

    if (freeVersion) {
        return (
            <div className={CTAStyle['CTA-Container']}>
                <a href={nomOneLink} target="_blank" rel="noopener noreferrer">
                    <button className={CTAStyle['btn-mail']} type='button'>Start Free Plan</button>
                </a>
            </div>
        );
    } else {
        return (
            <div className={CTAStyle['CTA-Container']}>
                <a id={CTAStyle['nom-one-button']} href={nomOneLink} target="_blank" rel="noopener noreferrer"  onClick={() => sendToDynamoDB('NOM ONE')}>
                    <button className={CTAStyle['btn-nom']} type='button'>Try NOM ONE for Free</button>
                </a>
                <a href='#business-signup'>
                    <button className={CTAStyle['btn-mail']} type='button'>Join mailing list</button>
                </a>
            </div>
        );
    }
}

export default CTAButtons;