import logo from "./logo.svg";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Switch,
  useLocation,
} from "react-router-dom";
import { useLayoutEffect, useState, useEffect } from "react";

// All the pages and the components for the webbsite

import Navbar from "./components/Navbar/Navbar";
import { Hero } from "./components/Hero/Hero";
import Business from "./screens/Business/Business";
import Terms from "./screens/Terms/Terms";
import Privacy from "./screens/Privacy/Privacy";
import { Contact } from "./screens/Contact/Contact";
import About from "./screens/About/About";
import Footer from "./components/Footer/Footer";
import Waitlist from "./components/Waitlist/Waitlist";
import Confirmation from "./modals/Confirmation/Confirmation";
import { HelmetProvider } from 'react-helmet-async';

// Bring back the screen to the top on navigation from one page to another page
const TopPage = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};


function App () {

  /* whether client screen < 700px */
  let mediaQuery = window.matchMedia('(max-width: 700px)').matches;
  let mediaQueryMedium = window.matchMedia('(max-width: 1200px)').matches;
  const [mobile, setMobile] = useState(mediaQuery);
  const [midsize, setMidsize] = useState(mediaQueryMedium);

  /* if mobile status changes, reload the component */
  useEffect(() => {
    const sizeListener = () => {
      if (window.innerWidth <= 700 && !mobile) {
        setMobile(true);
      } else if (window.innerWidth <= 1200 && !midsize) {
        setMidsize(true);
      } else if (window.innerWidth > 700 && mobile) {
        setMobile(false);
      }
    };
    window.addEventListener('resize', sizeListener);

    return () => {
      window.removeEventListener('resize', sizeListener);
    };
  }, [mobile, midsize]);


  return (
    <>
      {/* <Confirmation/>
    <Waitlist/> */}
    <HelmetProvider>
      <Router>
        <TopPage>
          <Navbar />
          <Routes>
            <Route exact path="/" element={<Hero isMobile={mobile} isMidsize={midsize} />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/about" element={<About isMobile={mobile} />} />
            <Route exact path="/business" element={<Business isMobile={mobile} />} />
            <Route exact path="/privacy" element={<Privacy />} />
            <Route exact path="/privacy-policy" element={<Privacy />} />
            <Route exact path="/privacy-policy/" element={<Privacy />} />
            <Route exact path="/terms" element={<Terms />} />
            <Route exact path="/waitlist" element={<Waitlist />} />
            <Route
              path="*"
              element={
                <h1 style={{ fontSize: 50, textAlign: "center", padding: 100 }}>
                  {" "}
                  Page Not Found
                </h1>
              }
            />
          </Routes>
          <Footer isMobile={mobile} />
        </TopPage>
      </Router>
    </HelmetProvider>
    </>
  );
}

export default App;
