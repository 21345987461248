import React from 'react';
import businessStyle from './Business.module.css';

const BusinessFeature = ({ imageSrc, altText, title, description }) => {
  return (
    <div className={businessStyle['business-feature']}>
      <img 
        className={businessStyle['business-feature-img']}
        src={imageSrc}
        alt={altText} 
      />
      <h3>{title}</h3>
      <p className={businessStyle['p1-b']}>{description}</p>
    </div>
  );
};

export default BusinessFeature;
