

export const Quote = ({ header, quotation, author, name, midsize }) => {


  let quoteClassName = 'quotes-container';
  if (midsize && name !== 'quote1') {
    quoteClassName += ' hiddenEl';
  }

  const handleSlideshow = elementName => {
    const quoteNumberEl = document.getElementById(elementName);
    const dots = document.querySelector('.dots');
    quoteNumberEl.classList.toggle('hiddenEl');
    if (midsize) {
      let quotes = document.querySelectorAll('.quotes-container');
      for (let el=0; el < quotes.length; el++) {
        if (elementName === quotes[el].id) {
          if ((el+1) === quotes.length) {
            quotes[0].classList.toggle('hiddenEl');
            dots.children[0].classList.toggle('selected');
          } else {
            quotes[el+1].classList.toggle('hiddenEl');
            dots.children[el+1].classList.toggle('selected');
          }
          dots.children[el].classList.toggle('selected');
        }
      }
    }
  };


  return (
    <div className={quoteClassName} id={name} onTouchEnd={() => handleSlideshow(name)} onClick={() => handleSlideshow(name)} >
      <img
        src="qotation.png"
        alt="qotation"
        style={{ height: 40, width: 40 }}
      />
      <h2
        style={{ fontSize: 24 }}
      >
        {header}</h2>
      <p
        style={{ fontSize: 19 }}
        className="quote"
      >
        {quotation}</p>
      <p
        className="quote-name"
        style={{ fontSize: 15, fontWeight: 600 }}
      >
        {author}
      </p>
    </div>
  );
};
