import stylesAbout from "./About.module.css";
import IOSButton from "../../components/Hero/IOSButton";

export const ArrowDiv = ({ handleDownloadButton, isMobile }) => {
  return (
    <>
      <div className={stylesAbout.arrowDiv}>
        <img
          style={{ height: 51, width: 48 }}
          src="three-arrow.png"
          alt="three-arrow"
        />
        <h1 className={stylesAbout.joinHeader} style={{ fontFamily: "Montserrat", marginBottom: "40px" }}>
          Join our Community!
        </h1>
      </div>
      <div className={stylesAbout['p1-div']} >
        <p className={stylesAbout.p1}>
          We've been working hard to ensure our app is a great fit for any
          user.
        </p>
        <p className={stylesAbout.p1}>
          {" "}
          Top of mind for us are busy business owners and social users. We're
          focusing on being inclusive and intuitive, simple to setup, easy to
          use.
        </p>
        <div className={stylesAbout.buttonAndArrow}>
          <IOSButton
            styleProp={{ marginTop: "78px" }}
            handleDownloadButton={handleDownloadButton}
            mediaQuery={isMobile}
          />
          <img
            className={stylesAbout.arrows}
            src="curly-arrow.png"
            alt="curly-arrow"
          />
        </div>
      </div>
    </>
  );
};
