import { ThankYouNote } from "./ThankYouNote.jsx";
import contactStyle from "./Contact.module.css";

export const ContactForm = ({ onSubmit, onInputChange, formStateProps, formSubmitStatus }) => {

  if (formSubmitStatus) {
    return (
      <>
        <ThankYouNote />
      </>
    );
  } else {
    return (
      <>
        <div className={contactStyle.name} >
          <div className={contactStyle.column} >
            <label>First Name</label>
            <input
              name="firstName"
              className={contactStyle['input-field']}
              type="text"
              onChange={onInputChange}
              value={formStateProps.firstName}
              required
            />
          </div>
          <div className={contactStyle.column} >
            <label>Last Name</label>
            <input
              name="lastName"
              className={contactStyle['input-field']}
              type="text"
              onChange={onInputChange}
              value={formStateProps.lastName}
              required
            />
          </div>
        </div>
        <div className={contactStyle.info} >
          <div className={contactStyle.name} >
            <div className={contactStyle.column} >
              <label>Email</label>
              <input
                name="email"
                className={contactStyle['input-field']} 
                type="email"
                onChange={onInputChange}
                value={formStateProps.email}
                required
              />
            </div>
            <div className={contactStyle.column} >
              <label>Phone Number</label>
              <input
                name="phone"
                className={contactStyle['input-field']} 
                type="tel"
                onChange={onInputChange}
                value={formStateProps.phone}
                required
              />
            </div>
          </div>
        </div>
        <div className={contactStyle.select} >
          <p> Select Subject</p>
          <div className={contactStyle.radio} >
            <label className={contactStyle['input-radio']} >
              <input
                type="radio"
                name="subject"
                value="General Inquiry"
                onChange={onInputChange}
                required
              />
              General Inquiry
            </label>
            <label className={contactStyle['input-radio']} >
              <input
                type="radio"
                name="subject"
                value="Business Inquiry"
                onChange={onInputChange}
                required
              />
              Business Inquiry
            </label>
            <label className={contactStyle['input-radio']} >
              <input
                type="radio"
                name="subject"
                value="App Inquiry"
                onChange={onInputChange}
                required
              />
              App Inquiry
            </label>
          </div>
        </div>
        <div className={contactStyle.message} >
          <label>Message</label>
          <textarea
            name="message"
            onChange={onInputChange}
            value={formStateProps.message}
            placeholder="Write your message..."
            required
          />
        </div>
        <div className={contactStyle['send-message']} >
          <button
            className={contactStyle.btn}
          >
            Send Message
          </button>
        </div>
      </>
    );
  }
};
