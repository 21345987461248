import React from 'react';
import privacyStyles from './Privacy.module.css';
import { Helmet } from 'react-helmet-async';

const Privacy = () => {
  return (
    <div className={privacyStyles.privacy}>
      <Helmet>
        <title>Privacy - nombolo</title>
        <meta name="description" content="Privacy policy for nombolo" />
      </Helmet>
      <h1 
        style={{
          marginBottom: '50px',
          textAlign: 'center',
          alignSelf: 'center'
        }}
      >
        Privacy Policy 
      </h1>
      <div className={privacyStyles['privacy-container']}>
        <h2>Privay Policy</h2>
        <p>
            Nombolo Inc. is a software company that has built Nombolo ( a mobile application) which is an app that provides a fast easy way to share your experiences and find out what is happening in the places around you. This privacy policy pertains to both the Nombolo mobile application and the Nombolo website www.nombolo.com
            When you use Nombolo, you’ll share some information with us. In the following document we will go over how we use your information, whom we share it with, and the controls we provide for you to access, update, and delete your information.
        </p>
        <h2>Information We Collect</h2>
        <p>There are three basic categories of information we collect:</p>
        <ul className={privacyStyles.lists}>
          <li>Information you provide.</li>
          <li>Information we get when you use our services.</li>
          <li>Information we get from third parties.</li>
        </ul>
        <h2>Information You Provide</h2>
        <p>
            When you interact with Nombolo, we collect information that you provide to us. For example, when you set up a Nombolo account, we will collect a few important details, such as your name, username, password, email address, phone number, and date of birth. Also any information that you provide while setting up your profile including pictures and text will be collected.
            Any information you send through our app, such as video and text will be stored in our database. It is your responsibility to determine what content you upload. Don’t send messages or share content that you wouldn’t want someone to save or share.
            In addition, when you contact customer support or communicate with us in any other way, we’ll collect whatever information you provide and/or that we need to resolve your question.
        </p>
        <h2>Information We Get When You Use Nombolo</h2>
        <p> When you use Nombolo, we collect information about how you’ve used the app. For example, we may know things like have you watched a particular video or visited a particular location. Below is a more complete explanation of the types of information we collect when you use Nombolo:</p>
        <h3>Usage Information</h3>

        <p>
            We collect information about your activity through our services. For example, we may collect information about:
            how you interact with our app, such as what videos you watch and what types of information you ask to view and the queries associated with.
            how you communicate with other Nombolo users and places, such as names, the time and date of your communications, the number of messages.
        </p>
        <h3>Content Information</h3>
        <p> We collect content you create on Nombolo, such as videos, how often your content is viewed, and who views it.</p>
        <h3>Device Information</h3>
        <p>  We collect information from and about the devices you use. For example, we collect:</p>
        <ul className={privacyStyles.lists}>
          <li>information about your hardware and software, such as the hardware model, operating system version, device memory, advertising identifiers, unique application identifiers, apps installed, unique device identifiers, browser type, language, battery level, and time zone;</li>
          <li>information from device sensors, such as accelerometers, gyroscopes, compasses, microphones, and whether you have headphones connected; and</li>
          <li>information about your wireless and mobile network connections, such as mobile phone number, service provider, IP address, and signal strength.</li>
        </ul>
        <ul className={privacyStyles.lists}>
          <li>Device Phonebook. With your permission we may collect information about your phonebook if you choose to invite your friends to Nombolo.</li>
          <li>Camera and Video. Some of the uses of Nombolo requires us to collect video and other information from your device’s camera. You will need to use your camera to shoot video if you want to post content on Nombolo.</li>
          <li>Location Information. When you use our services, with your permission, we may collect information about your location. Including collecting information about your precise location using methods that include GPS, wireless networks, cell towers, Wi-Fi access points, and other sensors, such as gyroscopes, accelerometers, and compasses.</li>
          <li>Information collected by cookies and other technologies such as web beacons, web storage, pixels, and unique advertising identifiers, to collect information about your activity, browser, and device. Typically you have the option to remove or block cookies from your web browser, but doing so may prevent some services from not working properly</li>
          <li>Information when you use our website, such as:
            <ul className={privacyStyles.lists}>
              <li>details about how you’ve used our services;</li>
              <li>device information, such as your web browser type and language;</li>
              <li>access times;</li>
              <li>pages viewed;</li>
              <li>IP address;</li>
              <li>identifiers associated with cookies or other technologies that may uniquely identify your device or browser; and</li>
              <li>pages you visited before or after navigating to our website.</li>
            </ul>
          </li>
        </ul>
        <h2>Information We Collect from Third Parties</h2>
        <p>We may collect information about you from other users and third parties. Here are a few examples:</p>
        <ul className={privacyStyles.lists}>
          <li>We may use third party analytics tools that collect and share your information.</li>
          <li>If another user uploads their contact list and your information is on that contact list, then that would become information we collect about you.</li>
        </ul>


        <h2>How We Use Information</h2>

        <p> So how do we use your information? Our main objective is to provide you, the user, the best experience possible while constantly making improvements to how we work. This is what that looks like:</p>
        <ul className={privacyStyles.lists}>
          <li>Develop, operate, improve, deliver, maintain, and protect our primary application and future offerings.</li>
          <li>send you communications by email and text.</li>
          <li>monitor and analyze user behavior to better understand trends</li>
          <li>Personalize the information and content we deliver to you.</li>
          <li>enhance and improve the safety and security of Nombolo.</li>
          <li>verify your identity, prevent fraud, and other unauthorized or illegal activity.</li>
          <li>Enhance and improve user experience by utilizing information we collect from cookies and other technologies.</li>
          <li>Information we collect that is needed to enforce our Terms of Service and other usage policies and comply with legal requirements.</li>
        </ul>
        <h2>How We Share Information</h2> 
        <p>We may share information about you in the following ways:</p>
        <ul>
          <li>
              With other Nombolo users. We may share the following information with other Nombolo users:
            <ul className={privacyStyles.lists}>
              <li>information about you, such as your username and profile photo.</li>
              <li>information about how you have interacted with our services. For example if you post a video that information will be available to all users on Nombolo.</li>
              <li>any additional information you have directed us to share. For example, Nombolo will share your information when you connect your Nombolo account to a third-party app, and if you share information or content from Nombolo to the third-party app.Content you post or submit to the Nombolo app.</li>
            </ul>
          </li>
          <li>
          With all Nombolo users, our business partners, and the general public. We may share the following information with all Nombolo users as well as with our business partners and the general public:
            <ul className={privacyStyles.lists}>
              <li>public information like your name, username, and profile pictures.</li>
              <li>Video submissions and any other user generated content. This content may be viewed and shared by the public at large both on and off our app, including through search results, on websites, in other apps, and in online and offline broadcasts.</li>
            </ul>
          </li>
          <li>
          With third parties. We may share information about you with service providers who perform services on our behalf.
            <ul className={privacyStyles.lists}>
              <li>We may share information about you with business partners that provide services and functionality to Nombolo such as analytics and marketing.</li>
              <li>We may share information about you, such as device and usage information, to help us and others prevent fraud.</li>
              <li>
                    We may share information about you for legal, safety, and security reasons. We may share information about you if we reasonably believe that disclosing the information is needed to:
                <ul className={privacyStyles.lists}>
                  <li>comply with any valid legal process, governmental request, or applicable law, rule, or regulation.</li>
                  <li>investigate, remedy, or enforce potential Terms of Service violations.</li>
                  <li>protect the rights, property, or safety of us, our users, or others.</li>
                  <li>detect and resolve any fraud or security concerns.</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>We may share information about you as part of a merger or acquisition. If Nombolo Inc. gets involved in a merger, asset sale, financing, liquidation or bankruptcy, or acquisition of all or some portion of our business to another company, we may share your information with that company before and after the transaction closes.</li>
        </ul>
        <h2>How Long We Keep Your Information</h2>
        <p>
            Nombolo lets you find out what is happening around you in near real time. That means that most content created and uploaded in Nombolo will automatically be deleted from our servers after being viewed and/or no longer being of use.
            We store other information for longer periods of time. For example:
        </p>
        <ul className={privacyStyles.lists}>
          <li>We store your basic account information—like your name, phone number, and email address until you ask us to delete it.</li>
          <li>We store location information for different lengths of time based on your device use and location permission settings.</li>
        </ul>
        <p>If you ever decide to stop using Nombolo, you can simply delete the application from your phone and send an email to hello@nombolo.com asking us to delete all of your information.</p>
        <p>We will communicate with an email reply and will delete all information as soon as we can and as long as it complies with any legal obligations that may exist. For example if legal authorities issued a request stipulating that your data needed to be preserved, we may or may have to comply depending on a court order.</p>

        <h3>Control over your information</h3>
        <p>We want you to be in control of your information, so we provide you with the following tools.</p>
        <ul className={privacyStyles.lists}>
          <li>Revoking permissions. You can grant or revoke permissions anytime either through our app or your personal device.</li>
          <li>Deletion. You can delete Nombolo from your mobile device at any time and request your information be deleted by sending an email to  <span>hello@nombolo.com</span> </li>
        </ul>
        <h2>Users in the European Union</h2>
        <p>If you’re based in the European Union, be aware that Nombolo Inc. is the controller of your personal information. Here is some additional information for your review:</p>
        <h3>Rights of access, deletion, correction, and portability</h3>
        <p>You can access, delete, correct, and move your personal information as described in the Control Over Your Information section above.</p>
        <h3>Bases for using your information</h3>
        <p> Your country only allows us to use your personal information when certain conditions apply. These conditions are called “legal bases” and, at Nombolo, we typically rely on one of four:</p>
        <ul className={privacyStyles.lists}>
          <li>Legitimate interest. We may use your information because we have—or a third party has—a legitimate interest in doing so. For example, we need to use your information to provide and improve our services, including protecting your account, delivering your video and requests, providing customer support, and helping you find the right content at the right time.</li>
          <li>Consent. An example of this would be asking you permission to share your location to help improve the functionality of Nombolo. You will have the right to revoke or allow device permissions.</li>
          <li>Legal obligation. We may be required to use your personal information to comply with the law, like when we respond to valid legal process or need to take action to protect our users.</li>
        </ul>
        <h3>Your Right to Object</h3>
        <p> You have the right to object to our use of your information. You will have the ability to delete data, and the application from your device. In addition you can contact us at hello@nombolo to request that we delete all data associated with.</p>
        <h3>California Residents</h3>
        <p>If you’re a California resident, you have certain privacy rights under California law, including the California Consumer Privacy Act of 2018 (“CCPA”).</p>
        <h3>Children</h3>
        <p>Our services are not intended for—and we don’t direct them to—anyone under 13. And that’s why we do not knowingly collect personal information from anyone under 13. In addition, we may limit how we collect, use, and store some of the information of EU users between 13 and 16. In some cases, this means we will be unable to provide certain functionality to these users. If we need to rely on consent as a legal basis for processing your information and your country requires consent from a parent, we may require your parent’s consent before we collect and use that information.</p>
        <h3>Revisions to the Privacy Policy</h3>
        <p>As our services and application evolve it is certain that changes to this policy will be made. We will notify you of when this occurs either through email, text, or within the application itself as you log in.</p>
      </div>
    </div>
  );
};

export default Privacy;
