import React, { useEffect, useRef, useState } from "react";
import Waitlist from "../../components/Waitlist/Waitlist";
import { Link } from "react-router-dom";
import businessStyle from "./Business.module.css";
import { BusinessContact } from "./BusinessContact";
import BusinessFeature from "./BusinessFeature";
import happySVG from "./happySVG.svg";
import { RewardImageWrapper } from "./RewardImageWrapper";
import { Helmet } from "react-helmet-async";
import CTAButtons from "../../components/CTA/CTAButtons";

const Business = ({ isMobile }) => {

  const contactFormRef = useRef(null);
  const [isToggled, setIsToggled] = useState(false)

  const handleToggleChange = () => {
    setIsToggled(!isToggled);
  }

  function handleScrollToForm () {
    contactFormRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'center'
    });
  }

  return (
    <div className={businessStyle.business} >
      <Helmet>
        <title>Business - nombolo</title>
        <meta name="description" content="See how we help small businesses succeed" />
      </Helmet>
      <div className={businessStyle['business-container']} >
        <div className={businessStyle['business-frame-1']} >
          <div className={businessStyle['community']} >
            <h1
              className={businessStyle.h1}
              style={{ color: "#352B4", fontFamily: "Montserrat", margin: 0 }}>
              Grow your business <br />
              with our NOM ONE membership!
            </h1>
            <p className={businessStyle['p1-b']}  style={{ margin: 0 }}>
              we have created <br />
              affordable, flexible, engaging <br />
              loyalty app for you and your customers.
            </p>
            <CTAButtons/>
          </div>
          <div className={businessStyle['dual-iphone-wrapper']} >
            <img
              className={businessStyle['dual-iphone']} 
              src="iPhone-15-Pro-1.png"
              alt="images of iphone 15"
            />
          </div>
        </div>
        <div className={businessStyle['business-frame-2']} >
          <div className={businessStyle['quote-wrapper']}>
            <img
              className={businessStyle['left-large-quote']}
              src="large-quote.png"
              alt="large quotation mark"
            />
          </div>
          <div className={businessStyle['business-frame-sub-frame-2']} >
            <h2 className={businessStyle['loyal-customers']} >
              Did you know...
            </h2>
            <p className={businessStyle['p1-b']} >
              Customers who participate in loyalty programs typically spend
              <span> 12% to 18% </span>
              more than other customers
            </p>
            <p className={businessStyle['p1-b']} >
              Increasing customer retention by just 5% can
              <span> increase profits by 25% </span>
              or more
            </p>
            <p className={businessStyle['p1-b']} >
              <span> 88% of loyal </span>
              customers recommend businesses to people they know
            </p>
          </div>
          <div className={businessStyle['quote-wrapper']}>
            <img
              className={businessStyle['right-large-quote']}
              src="large-quote.png"
              alt="large quotation mark"
            />
          </div>
        </div>
        <div className={businessStyle['business-frame-3']} >
          <div className={businessStyle['sub-business-frame-3']}>
            <p className={businessStyle['loyal-customers'] }>Loyal customers love to be loved</p>
            <p className={businessStyle['loyal-customers-sub']}>
              We know it's competitive out there. Treat your returning customers to personalized rewards, 
              <br/>
              and stand out from your competitors
            </p>
            <span className={businessStyle['nombolo-makes']}>
              nombolo makes it 
              <span id={businessStyle['easy']}> easy</span>
               <br/>
              to reward!
            </span>
            <p className={businessStyle['customers-know']}>
              customers know stamp cards. now you can give your customers digital stamps that become.
              <br/>
              <span>Rewards </span>
              - always giving customers a reason to come back
            </p>
            {/* <p><br/></p> */}
            <p><br/></p>
            <CTAButtons/>
          </div>
          <div className={businessStyle['reward-img-wrapper']}>
            <img
              className={businessStyle['coffee-cafe']}
              src="should-wang-ye5T5R0G-GA-unsplash 1.png"
              alt="man in a food cart"
            />
          </div>
        </div>
        <div className={businessStyle['business-frame-4']}>
          <div className={businessStyle['stamp-card-img-wrapper']}>
            <img 
              className={businessStyle['stamp-img']}
              src='Free_Iphone_15_Mockup_4 (10) 2.png'
              alt="digital stamp card on phone" />
          </div>
          <div className={businessStyle['why-digital']}>
            <p className={businessStyle['loyal-customers']}>Why a digital stamp card system?</p>
            <ul>
              <li className={businessStyle['digital-list-item']}>better than paper, more sustainable than plastic</li>
              <li className={businessStyle['digital-list-item']}>customers can’t lose or forget cards</li>
              <li className={businessStyle['digital-list-item']}>no card inventory headaches for you</li>
            </ul>
          </div>
        </div>
        <div className={businessStyle['business-frame-5']}>
          <p className={businessStyle['loyal-customers']}>Introducing</p>
          <img className={businessStyle['nom-crown']} src="Crown (1).png" alt="NOM ONE crown"/>
          <h2 id={businessStyle['nom-one-membership']}><span style={{ color: '#5C24C9' }}>NOM ONE</span> membership</h2>
          <p id={businessStyle['membership-info']}>A membership program which helps you to Reduce Marketing Costs, Boost Customer Loyalty, and Fuel Business Growth with Our
 <br/><span style={{ color: '#5c24c9'}}>Digital Reward System</span></p>
          <a className={businessStyle['join-button']} href='#business-signup'>
            <button className={businessStyle['btn-nom']}>Try NOM ONE free for 14 days</button>
          </a>
          <p><br /></p>
          <h2 className={businessStyle['you-can']}>With the nombolo app you can:</h2>
          <div className={businessStyle['you-can-list']}>
              <BusinessFeature 
              imageSrc='connect-mobile.png'
              altText='fist bumping'
              title='Promote your business'
              description='Post marketing info, specials and more.
              nombolo lets you post as often as you like!
              And enjoy word-of-mouth referrals when
              customers post your business on nombolo, which reduces marketing costs.'
            />
              <BusinessFeature 
              imageSrc='get-gift.png'
              altText='receiving gift'
              title='Customize Rewards'
              description='nombolo lets you set up the loyalty program you want,
              Treat your returning customers
              to rewards. You’ll stand out from the competition,
              and keep your customers happy and coming back'
            />
              <BusinessFeature 
              imageSrc='mobile-reward.png'
              altText='showing stamps'
              title='Digital Stamp Cards'
              description='Give your customers digital stamps that become 
              Rewards - always giving customers a reason to come back. Customers can’t lose or forget cards, and its more sustainable.'
            />
          </div>
          <div className={businessStyle['business-prices-frame']}>
            <h2 className={businessStyle['you-can']}>Ready to start?</h2>
            <p id={businessStyle['download-info']}>download the app and set up your loyalty progam today!</p>
            <label className={businessStyle['toggle-switch']}>
              <input 
                type="checkbox" 
                checked={isToggled}
                onChange={handleToggleChange} />
              <span className={businessStyle['slider']}></span>
            </label>
            <div class={businessStyle['pricing-options']}>
              <div className={businessStyle['price-free']}>
                <h3 id={businessStyle['free']}>FREE</h3>
                <span id={businessStyle['free-price']}>$0</span>
                <ul className={businessStyle['feature-unavailable']}>
                  <li>One post a week</li>
                  <li>Unlimited Posts</li>
                  <li>Customer Interaction</li>
                  <li>Share Videos with Users</li>
                  <li>Create Custom Rewards</li>
                  <li>Tracking Insights (coming soon)</li>
                </ul>
                <CTAButtons freeVersion={true}/>
              </div>
              <div className={businessStyle['price-nom-one']}>
                {!isToggled && (
                  <div id={businessStyle['highlight']}>
                    Half yearly plan<br/> Best Value!
                  </div>
                )}
                <h3 id={businessStyle['nom-one-price-title']}>NOM ONE</h3>
                <span>
                  {isToggled ? <p id={businessStyle['nom-one-price']}>$49.99</p> : <p id={businessStyle['nom-one-price']}>$284.99 <span id={businessStyle['save']}>(Save 5%)</span></p>}
                </span>
                <p>
                  {isToggled ? 'Billed monthly after 30 days trial' : 'Billed every 6 months after 30 days trial'}
                </p>
                <ul className={businessStyle['nomone-item-features']}>
                  <li>Unlimited Posts</li>
                  <li>Customer Interaction</li>
                  <li>Share Videos with Users</li>
                  <li>Create Custom Rewards</li>
                  <li>Tracking Insights (coming soon)</li>
                </ul>
                <a className={businessStyle['join-button']} href='#business-signup'>
                  <button className={businessStyle['btn-nom']}>Try NOM ONE free for 14 days</button>
                </a>
              </div>
            </div>
            <p>*Cancel anytime</p>
          </div>
        </div>
        <div
          className={businessStyle['business-signup']}
          id='business-signup'
          ref={contactFormRef}
        >
          <div className={businessStyle['signupForm']} >
            <BusinessContact scrollToForm={() => handleScrollToForm()}/>
          </div>
          <div className={businessStyle.svgWrapper}>
              <img src={happySVG} alt="happy woman, food truck, and stars" />
            </div>
        </div>
      </div>
    </div>
  );
};

export default Business;
