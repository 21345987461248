import React from 'react';
import './Terms.css';
import { Helmet } from 'react-helmet-async';

const Terms = () => {
    return (
        <div className="terms">
            <Helmet>
                <title>Terms - nombolo</title>
                <meta
                    name="description"
                    content="nombolo terms and conditions"
                />
            </Helmet>
            <h1>Nombolo Terms and Conditions</h1>
            <div class="terms-container">
                <p class="c0"></p>
                <p class="c0">
                    <span class="c2 c6">1. Acceptance of Terms</span>
                </p>
                <p class="c0">
                    <span class="c1">
                        By accessing or using the Nombolo, you agree to be bound
                        by these Terms and Conditions. If you disagree with any
                        part of the terms, you may not access the App.
                    </span>
                </p>
                <p class="c0">
                    <span class="c6 c2">2. User Accounts</span>
                </p>
                <ul class="c3 lst-kix_737na4d3rjcn-0 start">
                    <li class="c0 c4 li-bullet-0">
                        <span class="c2">Eligibility:</span>
                        <span class="c1">
                            &nbsp;You must be at least 13 years old to use the
                            App.
                        </span>
                    </li>
                    <li class="c0 c4 li-bullet-0">
                        <span class="c2">Account Creation:</span>
                        <span class="c1">
                            &nbsp;You must provide accurate and complete
                            information when creating an account.
                        </span>
                    </li>
                    <li class="c0 c4 li-bullet-0">
                        <span class="c2">Account Security:</span>
                        <span class="c1">
                            &nbsp;You are responsible for maintaining the
                            confidentiality of your account credentials.
                        </span>
                    </li>
                </ul>
                <p class="c0">
                    <span class="c6 c2">3. Content</span>
                </p>
                <ul class="c3 lst-kix_q3hjzsvw17pl-0 start">
                    <li class="c0 c4 li-bullet-0">
                        <span class="c2">User Responsibility:</span>
                        <span class="c1">
                            &nbsp;Users are solely responsible for the content
                            they post on Nombolo, including videos,
                            conversations, and pictures.
                        </span>
                    </li>
                    <li class="c0 c4 li-bullet-0">
                        <span class="c2">Prohibited Content:</span>
                        <span class="c1">
                            &nbsp;You agree not to post content that is:
                        </span>
                    </li>
                </ul>
                <ul class="c3 lst-kix_q3hjzsvw17pl-1 start">
                    <li class="c0 c5 li-bullet-0">
                        <span class="c1">Illegal, harmful, or offensive.</span>
                    </li>
                    <li class="c0 c5 li-bullet-0">
                        <span class="c1">
                            Infringing upon the intellectual property rights of
                            others.
                        </span>
                    </li>
                    <li class="c0 c5 li-bullet-0">
                        <span class="c1">Spam or promotional in nature.</span>
                    </li>
                    <li class="c0 c5 li-bullet-0">
                        <span class="c2">
                            Child Sexual Abuse Material (CSAM):
                        </span>
                        <span class="c1">
                            &nbsp;Nombolo has a zero-tolerance policy towards
                            CSAM. CSAM is any visual depiction, including
                            photos, videos, and computer-generated images, that
                            involves a minor (any person under the age of 18)
                            engaging in sexually explicit conduct. This
                            includes, but is not limited to, depictions of:
                        </span>
                    </li>
                </ul>
                <ul class="c3 lst-kix_q3hjzsvw17pl-2 start">
                    <li class="c0 c7 li-bullet-0">
                        <span class="c1">Sexual acts with a minor.</span>
                    </li>
                    <li class="c0 c7 li-bullet-0">
                        <span class="c1">
                            Exploitation of a minor for sexual purposes.
                        </span>
                    </li>
                    <li class="c0 c7 li-bullet-0">
                        <span class="c1">Indecent exposure of a minor.</span>
                    </li>
                    <li class="c0 c7 li-bullet-0">
                        <span class="c1">
                            Content that encourages or promotes child sexual
                            abuse.
                        </span>
                    </li>
                </ul>
                <ul class="c3 lst-kix_q3hjzsvw17pl-0">
                    <li class="c0 c4 li-bullet-0">
                        <span class="c2">Content Removal:</span>
                        <span class="c1">
                            &nbsp;Nombolo reserves the right to remove any
                            content that violates these Terms and Conditions.
                        </span>
                    </li>
                </ul>
                <p class="c0">
                    <span class="c6 c2">
                        4. CSAM Reporting and Consequences
                    </span>
                </p>
                <ul class="c3 lst-kix_tq1a1yhnrv2c-0 start">
                    <li class="c0 c4 li-bullet-0">
                        <span class="c2">Reporting:</span>
                        <span class="c1">
                            &nbsp;If you encounter any content that you believe
                            may be CSAM, please report it to us immediately
                            through the in-app reporting mechanisms.
                        </span>
                    </li>
                    <li class="c0 c4 li-bullet-0">
                        <span class="c2">
                            Consequences of Violating this Policy:
                        </span>
                        <span class="c1">
                            &nbsp;Any user found to be creating, storing,
                            sharing, or promoting CSAM on the Nombolo platform
                            will face immediate account termination. We will
                            also report such activity to the National Center for
                            Missing and Exploited Children (NCMEC) and cooperate
                            fully with law enforcement investigations.
                        </span>
                    </li>
                </ul>
                <p class="c0">
                    <span class="c6 c2">5. Intellectual Property</span>
                </p>
                <ul class="c3 lst-kix_eotrvtddn3ga-0 start">
                    <li class="c0 c4 li-bullet-0">
                        <span class="c2">Ownership:</span>
                        <span class="c1">
                            &nbsp;Nombolo retains all rights, title, and
                            interest in and to the App, including all
                            intellectual property rights.
                        </span>
                    </li>
                    <li class="c0 c4 li-bullet-0">
                        <span class="c2">User Content License:</span>
                        <span class="c1">
                            &nbsp;By posting content on Nombolo, you grant
                            Nombolo a non-exclusive, royalty-free, worldwide
                            license to use, reproduce, modify, adapt, publish,
                            translate, distribute, and display such content.
                            &nbsp;
                        </span>
                    </li>
                    <li class="c0 c4 c10 li-bullet-0">
                        <span class="c1"></span>
                    </li>
                </ul>
                <p class="c0">
                    <span class="c6 c2">6. Privacy</span>
                </p>
                <ul class="c3 lst-kix_o675e6ri7qdj-0 start">
                    <li class="c0 c4 li-bullet-0">
                        <span class="c2">Data Collection:</span>
                        <span class="c1">
                            &nbsp;Nombolo collects and uses user data in
                            accordance with its Privacy Policy.
                        </span>
                    </li>
                    <li class="c0 c4 li-bullet-0">
                        <span class="c2">Data Security:</span>
                        <span class="c1">
                            &nbsp;Nombolo takes reasonable measures to protect
                            user data from unauthorized access, use, or
                            disclosure.
                        </span>
                    </li>
                </ul>
                <p class="c0">
                    <span class="c6 c2">7. Disclaimer of Warranties</span>
                </p>
                <ul class="c3 lst-kix_5hsoo6k53kn3-0 start">
                    <li class="c0 c4 li-bullet-0">
                        <span class="c2">No Warranty:</span>
                        <span class="c1">
                            &nbsp;The App is provided &quot;as is&quot; without
                            warranty of any kind, express or implied.
                        </span>
                    </li>
                    <li class="c0 c4 li-bullet-0">
                        <span class="c2">Limitation of Liability:</span>
                        <span class="c1">
                            &nbsp;Nombolo shall not be liable for any damages
                            arising from the use of the App.
                        </span>
                    </li>
                </ul>
                <p class="c0">
                    <span class="c6 c2">8. Termination</span>
                </p>
                <ul class="c3 lst-kix_y3fx0q99rf55-0 start">
                    <li class="c0 c4 li-bullet-0">
                        <span class="c2">Termination by User:</span>
                        <span class="c1">
                            &nbsp;You may terminate your account at any time.
                        </span>
                    </li>
                    <li class="c0 c4 li-bullet-0">
                        <span class="c2">Termination by Nombolo:</span>
                        <span class="c1">
                            &nbsp;Nombolo may terminate your account for any
                            reason, including violation of these Terms and
                            Conditions.
                        </span>
                    </li>
                </ul>
                <p class="c0">
                    <span class="c6 c2">9. Governing Law</span>
                </p>
                <p class="c0">
                    <span class="c1">
                        These Terms and Conditions shall be governed by and
                        construed in accordance with the laws of the State of
                        Oregon. &nbsp;
                    </span>
                </p>
                <p class="c0">
                    <span class="c6 c2">10. Changes to Terms</span>
                </p>
                <p class="c0">
                    <span class="c1">
                        Nombolo reserves the right to modify these Terms and
                        Conditions at any time.
                    </span>
                </p>
                <p class="c0">
                    <span class="c6 c2">11. Contact Us</span>
                </p>
                <p class="c0">
                    <span class="c1">
                        If you have any questions about these Terms and
                        Conditions, please contact us at <a href="mailto:support@nombolo.com">support@nombolo.com</a>
                    </span>
                </p>
              
        
            </div>
        </div>
    );
};

export default Terms;
