import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

export const NomboloBusinessIcon = ({ toggleState }) => {
  const location = useLocation();

  const [ navImageUrl, setNavImageUrl ] = useState('applogo.png');
  let logoStyle = {};

  useEffect(() => {
    if (location.pathname === '/business') {
      setNavImageUrl('largeBusiness.svg');
      logoStyle = {
        width: "109px",
        height: "24px"
      }
    } else {
      setNavImageUrl('applogo.png');
      logoStyle = {
        width: "130px",
        height: "18px"
      }
    }

  }, [location]);

  return (
    <>
      <Link onClick={ toggleState } to="/">
        <img className={logoStyle} src={navImageUrl} alt="logo" />
      </Link>
    </>
  );
};
