/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./Hero.css";
import reward from "./reward.png";
import IOSButton from "./IOSButton";
import { Quote } from "./Quote";
import heroLogo from "./heroLogo.png";
import heroLogoMobile from "./heroLogoMobile.png";
import { ArrowDiv } from "../../screens/About/ArrowDiv";
import { Helmet } from 'react-helmet';

export const handleDownloadButton = (event) => {
  if (event.target.parentElement.id === 'appleButton' || navigator.userAgent.toLowerCase().includes('mac')) {
    window.open(
      "https://apps.apple.com/us/app/nombolo-community/id1506060452",
      "_blank"
    );
  } else if (event.target.parentElement.id === 'androidButton' || navigator.userAgent.toLowerCase().includes('android')) {
    window.open(
      "https://play.google.com/store/apps/details?id=com.nombolo.nombolo&hl=en_US&gl=US&pli=1",
      "_blank"
    );
  }
};

export const Hero = ({ isMobile, isMidsize }) => {

  return (
    <div className="hero">
      <Helmet>
        <title>Home - nombolo</title>
        <meta name="description" content="We're building a new type of reward program" />
      </Helmet>
      <div className="header fade-in ">
        <img
          className="nombolo"
          src={heroLogo}
          srcSet={`${heroLogo} 1885w, ${heroLogoMobile} 936w`}
          sizes={`(max-width: 700px) 225px, 628px`}
          alt="nombolo logo"
        />
        <p className="sentence">
          A social app enabling small businesses & social users{" "}
          <span>to connect, share and reward</span>{" "}
        </p>
        <IOSButton
          styleProp={{ marginTop: "70px", marginBottom: "220px" }}
          handleDownloadButton={handleDownloadButton}
          mediaQuery={isMobile}
        />
      </div>
      <div className="">
        <img className="hero-image-1" src="sample.png" alt="mobile" />
      </div>

      <div className="test">
        <div className="connect-share-reward-header">
          <h1 className="sub-header2">Connect. Share. Reward</h1>
          <p className="sentence2">
            Nombolo makes it easy for people to connect with each other, share
            places they love, and earn rewards from their favorite places.
          </p>
        </div>
        <div className="connect-share-reward">

          <div className="connectContainer">
            <div className="subContainer">
              <h2
                className="connect"
                style={{
                  color: "#BC74CE",
                }}
              >
                Connect
              </h2>
              <p className="p">
                <span style={{ fontWeight: 600 }}> Curious about a place?</span>
              </p>
              <p className="p">
                Drop a geo-located Ask on the map and get a real-life video
                answer from the community.
              </p>
              <p className="p">
                <span style={{ fontWeight: 600 }}>
                  See an Ask on the map near you?
                </span>
              </p>
              <p className="p">
                Create a video Answer from the location, and help out Nombolo
                community members.
              </p>
            </div>
            <img className="hero-image" src="connect.png" alt="connect" />
            <div className="hidden">
              <img
                className="mobile-image"
                src="connect-mobile-design.png"
                alt="mobile-connect"
              />
            </div>
          </div>

        </div>
        <div className="connect-share-reward">
          <div className="connectContainer share" >
            <img className="hero-image" src="share.png" alt="share" />
            <div className="hidden">
              <img
                className="mobile-image"
                src="share-mobile-design.png"
                alt="mobile-share"
              />
            </div>
            <div className="subContainer">
              <h2
                className="connect"
                style={{
                  color: "#EA6656",
                }}
              >
                Share
              </h2>
              <p className="p">
                Post videos of the places you go. Our <span>geo-tech </span>
                ensures location accuracy.
              </p>
              <p className="p">
                Promote your favorite local businesses by posting videos about
                them.
              </p>
            </div>
          </div>
        </div>
        <div className="connect-share-reward">
          <div className="connectContainer reward">
            <div className="subContainer">
              <h2
                className="connect"
                style={{
                  color: "#49C4B5",
                }}
              >
                Reward
              </h2>
              <p className="p">
                Nombolo makes it easy for small businesses to reward their
                customer.
              </p>
              <p className="p">
                Earn rewards when you download the app and create an account!
              </p>
            </div>
            <div>
              <img className="hero-image image-offset" src={reward} alt="reward" />
            </div>
            <div className="hidden">
              <img
                className="mobile-image"
                src="reward-mobile-design.png"
                alt="reward-mobile"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="feedback">
        <div className="feedback-container">
          <div className="feedback-h2">
            <h2>Love from our Community</h2>
          </div>
          <div className="quotes">
            <Quote
              header="A great app for people to connect and share."
              quotation="I always cannot find my stamp card for those boba tea shops. With this app, I can have all the stamp cards in one place."
              author="Ben and Carrie"
              name="quote1"
              midsize={isMidsize}
            />
            <Quote
              header="Great to build up small businesses!"
              quotation="Create real-time videos, get rewarded by businesses, and ask questions about different areas for responses from users or businesses. Highly recommended!"
              author="CanIhasacheeseburger"
              name="quote2"
              midsize={isMidsize}
            />
            <Quote
              header="Great app for finding local spots!"
              quotation="This app makes it incredibly convenient to find local restaurants and get rewarded for eating there. Asks is a great feature!"
              author="Kevin Judge"
              name="quote3"
              midsize={isMidsize}
            />
          </div>
          <div className="dots">
            <div className="dot selected"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
        </div>
      </div>
      <div className="for-business" style={{ width: "100%" }}>
        <div className="businesss">
          <div className="for-business-container">
            <h2>nombolo for small businesses</h2>
            <p className="hide-p">
              Nombolo brings customers and small business together,
              strengthening engagement and connection, and ultimately the
              businesses themselves.
            </p>
            <Link to="/business">
              <button className="business-button">explore business features</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="download-link">
        <ArrowDiv
          handleDownloadButton={handleDownloadButton}
          isMobile={isMobile}
        />
      </div>
    </div>
  );
};
