import { useState } from "react";
import contactStyle from "./Contact.module.css";
import { ContactForm } from "./ContactForm.jsx";
import { useRef } from "react";
import { Helmet } from "react-helmet-async";


export const Contact = () => {

  const [formSubmitStatus, setFormSubmitStatus] = useState(false);

  const contactFormRef = useRef(null);

  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const handleInputChange = event => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };

  const handleSubmit = event => {
    event.preventDefault();
    sendToDynamoDB(formState);
    setFormState({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    });
    const radioButtons = document.querySelectorAll('input[type="radio"]');
    radioButtons.forEach((radio) => {
      radio.checked = false;
    });
    setFormSubmitStatus(true);
    handleScrollToForm();
  };

  const sendToDynamoDB = async (item) => {
    try {
      // const response = await API.post("WebConnectInfoAPI", "/contact", {
      //   body: {
      //     id: Date.now().toString(),
      //     firstName: item.firstName,
      //     lastName: item.lastName,
      //     email: item.email,
      //     phone: item.phone,
      //     subject: item.subject,
      //     message: item.message,
      //   },
      // });
    } catch (error) {
      console.error(error);
    }
  };

  function handleScrollToForm () {
    contactFormRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'center'
    });
  }

  return (
    <div className={contactStyle.contact} >
      <Helmet>
        <title>Contact - nombolo</title>
        <meta name="description" content="Reach out to our team more any questions" />
      </Helmet>
      <h1 className={contactStyle['h1-contact']} >Let's Connect!</h1>
      <p
        className={contactStyle['p-contact']}
        ref={contactFormRef}
      >
        Have a question? Or comment? We'd love to hear from you.
      </p>
      <div className={contactStyle['contact-container']} >
        <form onSubmit={handleSubmit}>
          <div className={contactStyle['form-container']} >
            <ContactForm
              onSubmit={handleSubmit}
              onInputChange={handleInputChange}
              formStateProps={formState}
              formSubmitStatus={formSubmitStatus}
            />
          </div>
          <div className={contactStyle['fist-bump']} >
            <img src="fist-bump-contact.png" alt="fist" />
          </div>
        </form>
      </div>
    </div>
  );
};

