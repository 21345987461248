import React, { useState } from 'react';

import './Waitlist.css'
import { AiOutlineCloseCircle } from "react-icons/ai";
   



const Waitlist = ({open}) => {
    if(open!=true) return null
  return (
    <div className='waitlist'>
        <div className='waitlist-container'>
            <div className='icon-container'>
                <div className=''>
                   <img className='sprinkles' src="sprinkles.png" alt="sprinkles" />
                </div>
                <div>
                    <button  style={{border:'none'}}>
                     <  AiOutlineCloseCircle size={30}/>
                    </button>
                  
                </div>
            </div>
            <div className='header-waitlist'>
                <h1>Join the Mailing List!</h1>
                <h2>Sign up to get the monthly newsletter with product updates, as well as exclusive content and deals!</h2>
            </div>
            <div className=''>
                <form action="">
                    <div className='form'>
                        <label htmlFor=""> First Name <span className='asterics'>*</span></label>
                        <input  className='inputs'type="text" />
                        <label htmlFor=""> Businesss Name <span className='asterics'>*</span></label>
                        <input className='inputs' type="text" />
                        <label htmlFor="">Email <span className='asterics'>*</span></label>
                        <input className='inputs' type="text" />
                        <label htmlFor=""> Phone Number</label>
                        <input className='inputs' type="text" />
                    </div>
                    
                </form>
            </div>
            <div  className='waitlist-btn'> 
                <button className='btn'>I'm in</button>
            </div>
            <div  className='happy'>
              <img  src="happy.png" alt="happy" />
            </div>
        </div>
    </div>
  )
}

export default Waitlist