import { useEffect } from "react";

import businessContactStyle from "./BusinessContact.module.css";
import businessStyle from "./Business.module.css";
import orangeCurlyArrow from "./orangeCurlyArrow.svg";

export const BusinessContact = ({ scrollToForm }) => {

  useEffect(() => {
    // Create the main HubSpot script element
    const hsScript = document.createElement("script");
    hsScript.type = "text/javascript";
    hsScript.src = "//js.hsforms.net/forms/embed/v2.js";

    if (!document.getElementById('hs-script')) {
      hsScript.id = 'hs-script';
      document.body.appendChild(hsScript);
    }
    
    hsScript.onload = () => {
      document.getElementById("hubspot-form").innerHTML = ''; 
      // Only once the main script has loaded, execute the form creation script
      const hsFormScript = document.createElement("script");
      hsFormScript.innerHTML = `
        hbspt.forms.create({
          target: '#hubspot-form',
          region: "na1",
          portalId: "7343610",
          formId: "87546360-7e2a-40dd-9597-00dc19a1cfbf"
        });
      `;
      document.body.appendChild(hsFormScript);
    };

    // Append the main script to the body
    document.body.appendChild(hsScript);

    // Cleanup function to remove the main script if the component is unmounted
    return () => {
      document.body.removeChild(hsScript);
    };
  }, []);

  return (
    <div className={businessStyle["form-text"]} >
      <h1 className={businessStyle["form-header"]} >Interested in learning more?</h1>
      <h3 className={businessStyle["form-header-small"]} >Join the mailing list!</h3>
      <div id="hubspot-form"></div>
    </div>
  )
};

  // const [formSubmitStatus, setFormSubmitStatus] = useState(false);

  // const [formState, setFormState] = useState({
  //   firstName: "",
  //   lastName: "",
  //   email: "",
  //   phone: "",
  //   subject: "",
  //   message: "",
  // });

  // const handleInputChange = (event) => {
  //   setFormState({ ...formState, [event.target.name]: event.target.value });
  // };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   // console.log(formState);
  //   sendToDynamoDB(formState);
  //   setFormState({
  //     firstName: "",
  //     lastName: "",
  //     email: "",
  //     phone: "",
  //     subject: "",
  //     message: "",
  //   });
  //   // const radioButtons = document.querySelectorAll('input[type="radio"]');
  //   // radioButtons.forEach((radio) => {
  //   //   radio.checked = false;
  //   // });

  //   setFormSubmitStatus(true);
  //   scrollToForm();
  // };

  // const sendToDynamoDB = async (item) => {
  //   try {
  //     // hard code subject and message, otherwise will be empty on the backend
  //     const response = await API.post("WebConnectInfoAPI", "/contact", {
  //       body: {
  //         id: Date.now().toString(),
  //         firstName: item.firstName,
  //         lastName: item.lastName,
  //         email: item.email,
  //         phone: item.phone,
  //         subject: "Join the wait list",
  //         message: "Join the wait list",
  //       },
  //     });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };


//   if (formSubmitStatus) {
//     return (
//       <>
//         <h1 className={businessStyle["form-header"]} >Thank you!</h1>
//         <p className={businessStyle["form-p"]} >
//           We'll email you shortly with news.
//         </p>
//         <img
//           className={businessStyle["orange-curly-arrow"]}
//           src={orangeCurlyArrow}
//         />
//       </>
//     );
//   } else {
//     return (
//       <>
//         <div className={businessStyle["form-text"]} >
//           <h1 className={businessStyle["form-header"]} >Are you a business owner?</h1>
//           <h3 className={businessStyle["form-header-small"]} >Join the waitlist!</h3>
//           <p className={businessStyle["form-p"]} >
//             We'll let you know when a spot is ready and waiting!
//           </p>
//         </div>
//         <form onSubmit={handleSubmit}>
//           <div className={businessContactStyle["name"]} >
//             <div className={businessContactStyle["column"]} >
//               <label>
//                 First Name <span style={{ color: "rgba(204, 0, 0, 1)" }}> *</span> 
//               </label>
//               <input
//                 name="firstName"
//                 className={businessContactStyle["input-field"]}
//                 type="text"
//                 onChange={handleInputChange}
//                 value={formState.firstName}
//                 required
//               />
//             </div>
//             <div className={businessContactStyle["column"]} >
//               <label>
//                 Last Name <span style={{ color: "rgba(204, 0, 0, 1)" }}> *</span> 
//               </label>
//               <input
//                 name="lastName"
//                 className={businessContactStyle["input-field"]}
//                 type="text"
//                 onChange={handleInputChange}
//                 value={formState.lastName}
//                 required
//               />
//             </div>
//           </div>
//           <div className={businessContactStyle["info"]} >
//             <div className={businessContactStyle["name"]} >
//               <div className={businessContactStyle["column"]} >
//                 <label>
//                   Email <span style={{ color: "rgba(204, 0, 0, 1)" }}> *</span> 
//                 </label>
//                 <input
//                   name="email"
//                   className={businessContactStyle["input-field"]} 
//                   type="email"
//                   onChange={handleInputChange}
//                   value={formState.email}
//                   required
//                 />
//               </div>
//               <div className={businessContactStyle["column"]} >
//                 <label>Phone Number</label>
//                 <input
//                   name="phone"
//                   className={businessContactStyle["input-field"]}
//                   type="tel"
//                   onChange={handleInputChange}
//                   value={formState.phone}
//                   required
//                 />
//               </div>
//             </div>
//           </div>
//           <button className={businessContactStyle["btn"]} >I'm in</button>
//         </form>
//       </>
//     );
//   }
// };
